.collapse-toggle {
  cursor: pointer;
  display:flex; 
  align-items:center;
  /* color: rgb(47, 130, 208); */
  color:  rgb(156, 198, 125);
  color:  rgb(125, 166, 198);
  color:  rgb(92, 151, 197);
  color:  rgb(75, 148, 203);
  font-weight: 600;
  font-size: 0.97rem;
  margin-left: -0.3rem;
}

.collapse-icon {
  margin-right: 0.2rem;
}
