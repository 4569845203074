.code-block {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  /* max-width: 40rem; */
  background: rgba(40, 44, 52, 0.97) !important;
}

.code-block::-webkit-scrollbar {
  height: 10px;
}
.html.code-block::-webkit-scrollbar-track {
  background: rgba(179, 183, 192, 0.2);
  border-radius: 0px;
}
.code-block::-webkit-scrollbar-thumb {
  background: rgba(179, 183, 192, 0.5);
  border-radius: 0px;
}