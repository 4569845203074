
.program-menu {
  position: absolute;
  top: 5.5rem;
  left: 0;
  height: calc(100vh - 7rem);
  overflow-y: auto;
  width: 100%;
  padding-bottom: 20vh;
}
.program-menugroup {
  margin-bottom: 0.5rem;
  padding: 0.5rem var(--p-menu);
}
.program-menugroup_selected {
  /* 
  background-color: rgba(152, 195, 121, 0.3);
  background-color: rgb(158, 199, 30, 0.2);
  */
  /* background-color: rgba(152, 205, 114, 0.2); */
  /*
  background-image: radial-gradient(rgba(0, 0, 0, 0.06) 1px, transparent 0);
  background-size: 4px 4px;
  */
}

.program-menugroup__title {
  font-weight: 600;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  margin-bottom: 0.3rem;
  cursor: pointer;
}
.program-menugroup__items {

}
.program-menugroup__item {
  cursor: pointer;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  margin-bottom: 0.3rem;
  font-size: 0.95rem;
}
.program-menugroup__item_secondary {
  color: rgba(88, 101, 44, 0.8);
}