.markdown-block code {
  background-color: rgba(0,0,0,0.06);
  border-radius: 2px;
  /* padding: 0 0.1rem; */
  border: 1px solid rgba(0,0,0,0.02);
}

.markdown-block pre.code-block code {
  background-color: transparent;
}

.markdown-block mark code {
  background-color: rgba(0,0,0,0) !important;
  border: 0 !important;
  padding: 0 !important;
}

.markdown-block mark {
  background-color: rgb(158, 199, 30, 0.3);
  background-color: rgba(152, 205, 114, 0.5);
}
.mark-inside code {
  padding: 0;
}
.markdown-block strong {
  font-weight: 600;
  margin-bottom: -0.5rem;
}
.markdown-block .code-block {
  margin-left: 1.9rem !important;
}
.markdown-image {
  padding: 0 0 0 1.9rem;
}
.markdown-image img,
.markdown-image_fullwidth img {
  border: 1px solid rgba(0,0,0,0.1);
  margin-right: 3px;
  border-radius: 1px;
}
.markdown-image_fullwidth {
  padding-left: 0;
}

.markdown-block_task {
  /* margin-bottom: 2rem; */
}

.markdown-block p {
  margin: 0.8rem 0;
}
.markdown-block ul,
.markdown-block ol {
  margin: 0.8rem 0;
}

.program-task__content .markdown-block {
  margin: -0.5rem 0;
}
.program-task__content .markdown-block p {
  margin: 0.5rem 0;
}


/* todo: make 100% width work, instead of calculations */
.markdown-block {
  overflow: hidden;
  max-width: calc(100vw - var(--w-menu) - var(--w-extra) - 2 * var(--p-content-main) - 13px);
}

.program_closed {
  max-width: calc(100vw - var(--w-menu-closed) - 13px);
}
.program_closed .markdown-block {
  max-width: calc(100vw - var(--w-menu-closed) - var(--w-extra) - 2 * var(--p-content-main) - 13px);
}

@media screen and (max-width: 1000px) {
  .markdown-block {
    max-width: calc(100vw - var(--w-menu) - 2 * var(--p-content-main) - 13px);
  }
  .program_closed .markdown-block {
    max-width: calc(100vw - var(--w-menu-closed) - 1 * var(--p-content-main) - 13px);
  }
}

@media screen and (max-width: 750px) {
  .markdown-block {
    max-width: calc(100vw - var(--w-menu-closed) - var(--p-content-main)) !important;
  }
}