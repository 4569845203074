.program {
  display: flex;
  --w-menu: 250px;
  --w-menu-closed: 40px; 
  --p-menu: 1rem;
  --w-extra: 33vw;
  --p-content-main: 5rem;

  padding-left: var(--w-menu);
}
.program_closed {
  padding-left: var(--w-menu-closed);
}
.program-left {
  top: 0;
  width: var(--w-menu);
  min-height: 100vh;
  height: 100vh;
  flex-shrink: 0;
  position: fixed;
  left: 0;
  padding-left: var(--p-menu);
  background-color: white;
}
.program_closed .program-left {
  width: var(--w-menu-closed);
}
.program-center {
  min-height: 100vh;
  flex-grow: 1;
  padding-bottom: 35vh;
}
.program_closed .program-center {
  /* width: calc(100vw - var(--w-menu-closed)); */
}


.program-menu-toggle {
  margin-top: 1rem;
  cursor: pointer;
  align-items: center;
  display: flex;
}
.program-menu-toggle__text {
  font-weight: 500;
}


.program-nav {
  display: flex;
  height: 5rem;
}
.program-nav__main {
  flex-grow: 1;
  padding: 0.8rem 3rem 1rem 3rem;
  position: relative;
}
.program-nav__extra {
  width: var(--w-extra);
  max-width: 45%;
  flex-shrink: 0;
}
.program-search {
  position: absolute;
  right: 3rem;
  top: 0.8rem;
}
.program-extra-bkg {
  min-height: 100vh;
  height: 100vh;
  width: 100%;
  position: fixed;

  background-image: radial-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 0);
  background-size: 4px 4px;
  z-index: 1;
}


.homelink {
  --logo-h: 1.5rem;
  padding-top: 0.7rem;
  align-items: top;
  cursor: pointer;
}
.homelink__logo {
  max-height: var(--logo-h);
  display: inline-block;
  position: relative;
}
.homelink__text {
  display: inline-block;
  position: absolute;
  top: 0.7rem;
  margin-left: 0.5rem;
  line-height: var(--logo-h);
  font-weight: 600;
}


@media screen and (max-width: 1000px) {
  .program-nav__extra {
    display: none;
  }
  .program-extra-bkg {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .program {
  }
  .program-left {
    z-index: 1;
  }
  .program_open .program-left {
    box-shadow: 5px 5px 10px rgba(0,0,0,0.05);
  }
  .program-center {
    position: absolute;
    left: -1rem;
    z-index: 0;
  }
  .program-item__main {
    padding-right: 2rem;
  }
}